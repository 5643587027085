import React, { useState, useEffect } from "react";
import axios from "../utils/api";

import Layout from "../components/Layout";
import { IonImg, IonText } from "@ionic/react";

import Card from "../assets/images/card.jpg";
import Marker from "../assets/images/maker.gif";

const title = "A minha conta";

const Profile: React.FC = () => {
  const [profile, setProfile]: [any, any] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (!userData) return;

    const storedData: any = JSON.parse(userData);
    if (storedData && storedData.token && new Date(storedData.expirationTime) > new Date()) {
      axios.get(`/wp-json/qm/v1/profile?JWT=${storedData.token}`, {}).then((response) => {
        setProfile(response.data);
      });
    }
  }, []);

  return (
    <Layout title={title}>
      <div style={{ padding: 20, margin: "0 auto", maxWidth: 600 }}>
        <div
          style={{
            border: "2px solid #333",
            borderRadius: "10px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {profile && (
            <div style={{ fontSize: "9pt", fontWeight: "bold", color: "#bbb", position: "absolute", bottom: "8%", left: "7%", lineHeight: 1.67 }}>
              <IonText className="ion-text-uppercase">{profile.name}</IonText>
              <br />
              <IonText>//{profile.member}</IonText>
            </div>
          )}
          <IonImg src={Marker} style={{ position: "absolute", width: "auto", height: "100%", right: 0 }}></IonImg>
          <IonImg src={Card}></IonImg>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
