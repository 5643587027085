import { IonCol, IonGrid, IonRouterLink, IonRow } from "@ionic/react";
import React, { useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";

import { QmApi, QmApiError } from "../utils";
import Layout from "../components/Layout";
import WpBlocks from "../components/WpBlocks";
import AppText from "../components/AppText";

import { AuthContext } from "../context";

const Home: React.FC = () => {
  const [page, setPage] = useState<any>(false);
  const [title, setTitle] = useState<any>("");
  const [error, setError] = useState<any>("");

  const authContext = useContext(AuthContext);

  const loadPage = useCallback(() => {
    axios
      .get(QmApi("home"))
      .then((response) => {
        setPage(response.data);
        setTitle(response.data.title.rendered);
      })
      .catch((error) => {
        setError(QmApiError(error));
      });
  }, []);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  return (
    <Layout title={title} offline={error === "offline"}>
      {page && (
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              {!authContext.user && (
                <AppText>
                  <div className="ion-text-center">
                    Aceda a toda a informação e todos os serviços exclusivos QMLIFESTYLE fazendo o seu registo{" "}
                    <IonRouterLink href="/registo" style={{ textDecoration: "underline" }}>
                      aqui
                    </IonRouterLink>
                  </div>
                </AppText>
              )}

              <WpBlocks blocks={page.blocks} />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </Layout>
  );
};

export default Home;
