import React from "react";

interface Props {
  children: string;
}

const Error: React.FC<Props> = ({ children }) => {
  const errorStyte = {
    backgroundColor: "#ed576b",
    color: "#fff",
    fontSize: "11pt",
    borderRadius: "3px",
    padding: "7px 20px",
    margin: "0 10px",
  };
  return (
    <div className="ion-text-center" style={errorStyte}>
      {children}
    </div>
  );
};

export default Error;
