import React, { useEffect, useState, useCallback } from "react";
import { IonSlide, IonSlides } from "@ionic/react";
import axios from "axios";

interface Props {
  slider: string;
}

const api = "https://qmlifestyle.com";

const AppSlider: React.FC<Props> = ({ slider }) => {
  const [slides, setSlides] = useState<any>(false);

  const slidesDidLoad = () => {
    //console.log("Slider is loaded");
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 500,
    autoplay: {
      delay: 4000,
    },
  };

  const loadSlides = useCallback(() => {
    axios.get(`${api}/wp-json/wp/v2/vsz_responsive_slick/${slider}`).then((response) => {
      setSlides(response.data.slides);
    });
  }, [slider]);

  useEffect(() => {
    loadSlides();
  }, [loadSlides]);

  if (!slides) {
    return <div></div>;
  }

  return (
    <IonSlides pager={true} options={slideOpts} onIonSlidesDidLoad={slidesDidLoad} style={{ marginLeft: "-10px", marginRight: "-10px" }}>
      {slides.map((slide: any, index: number) => (
        <IonSlide key={index}>
          <img src={slide.image[0]} alt="" />
        </IonSlide>
      ))}
    </IonSlides>
  );
};

export default AppSlider;
