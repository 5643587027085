import React, { useRef, useState } from "react";
import { IonContent, IonFab, IonFabButton, IonIcon, IonToast, IonRouterLink, IonPage, IonBackButton } from "@ionic/react";
import { arrowUpOutline } from "ionicons/icons";

import AppHeader from "../components/AppHeader";
import AppTitle from "../components/AppTitle";
import AppContent from "../components/AppContent";
import AppSocial from "../components/AppSocial";
import Offline from "../components/Offline";

interface Props {
  offline?: boolean;
  title: string;
  back?: boolean;
  children: any;
}

const Layout: React.FC<Props> = ({ title, children, offline = false, back = false }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop(500);
  };

  return (
    <IonPage>
      <AppHeader title={title}></AppHeader>
      <IonContent
        ref={contentRef}
        scrollEvents={true}
        onIonScroll={(e) => {
          setScrollTop(e.detail.scrollTop);

          const backToTop = document.getElementById("back-to-top");
          if (scrollTop > 50) {
            backToTop?.classList.add("active");
          } else {
            backToTop?.classList.remove("active");
          }
        }}
      >
        <IonFab vertical="bottom" horizontal="end" slot="fixed" id="back-to-top" className={"teste"}>
          <IonFabButton onClick={scrollToTop} color="dark">
            <IonIcon icon={arrowUpOutline} />
          </IonFabButton>
        </IonFab>
        <IonToast isOpen={false} message="Your settings have been saved." duration={2000} />
        {offline && <Offline />}
        {!offline && (
          <>
            <AppTitle>{title}</AppTitle>
            <AppContent>
              {back && (
                <div className="ion-margin-bottom">
                  <IonBackButton text="Back" defaultHref="/" />
                </div>
              )}
              {children}
            </AppContent>
          </>
        )}
        <AppSocial />

        <div className="ion-text-center ion-padding" style={{ fontSize: "11pt" }}>
          <IonRouterLink href="/politica-privacidade">Política de Privacidade</IonRouterLink> | <IonRouterLink href="/termos-e-condicoes">Termos e Condições</IonRouterLink>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Layout;
