import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";

import { IonReactRouter } from "@ionic/react-router";
import PrivateRoute from "./components/PrivateRoute";

import { AuthContext } from "./context";

import Menu from "./components/Menu";
import Home from "./pages/Home";
import QmResorts from "./pages/QmResorts";
import QmResortDetails from "./pages/QmResortDetails";
import QmPartners from "./pages/QmPartners";
import QmPartnersCat from "./pages/QmPartnersCat";
import QmPartner from "./pages/QmPartner";
import QmExclusive from "./pages/QmExclusive";
import QmExclusiveDerails from "./pages/QmExclusiveDetails";
import QmCommunities from "./pages/QmCommunities";
import QmFamilyOffice from "./pages/QmFamilyOffice";
import QmCommunitiesDetails from "./pages/QmComunitiesDetails";
import QmMarketPlace from "./pages/QmMarketPlace";
import Golf from "./pages/Golf";
import GolfDetails from "./pages/GolfDetails";
import Marina from "./pages/Marina";
import Aerodromo from "./pages/Aerodromo";
import CentroHipico from "./pages/CentroHipico";
import RealEstate from "./pages/RealEstate";
import CascaisLifestyle from "./pages/CascaisLifestyle";
import QuemSomos from "./pages/QuemSomos";
import Registo from "./pages/Registo";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import TermosCondicoes from "./pages/TermosCondicoes";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/app.css";

/*
this.props.state.session.isAuthenticated
*/

const App: React.FC = () => {
  const [token, setToken] = useState(false);
  const [user, setUser] = useState(false);
  //const [tokenExpirationTime, setTokenExpirationTime]: [any, any] = useState(null);

  const login = (token: any, user: any) => {
    setToken(token);
    setUser(user);

    const expiration = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7); // 7 Remember 7 Days
    //setTokenExpirationTime(expiration); //set expiration time one hour from current time
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token,
        user,
        expirationTime: expiration.toISOString(),
      })
    );
  };

  const logout = () => {
    setToken(false);
    setUser(false);

    //setTokenExpirationTime(null);
    localStorage.removeItem("userData");
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (!userData) return;

    const storedData: any = JSON.parse(userData);
    if (storedData && storedData.token && new Date(storedData.expirationTime) > new Date()) {
      login(storedData.token, storedData.user);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, user, login, logout, redirect: "/" }}>
      <IonApp>
        <IonReactRouter>
          <Menu user={user} />

          <IonRouterOutlet id="main-content">
            <Route path="/" component={Home} exact={true} />

            <PrivateRoute token={token} path="/qm-resorts" component={QmResorts} exact={true} />
            <PrivateRoute token={token} path="/resort/:id" component={QmResortDetails} exact={true} />
            <PrivateRoute token={token} path="/qm-partners" component={QmPartners} exact={true} />
            <PrivateRoute token={token} path="/partners/:id" component={QmPartnersCat} />
            <PrivateRoute token={token} path="/partner/:id" component={QmPartner} />
            <PrivateRoute token={token} path="/qm-exclusive" component={QmExclusive} exact={true} />
            <PrivateRoute token={token} path="/exclusive/:id" component={QmExclusiveDerails} exact={true} />
            <Route path="/qm-communities" component={QmCommunities} exact={true} />
            <Route path="/qm-family-office" component={QmFamilyOffice} exact={true} />
            <Route path="/communities/:id" component={QmCommunitiesDetails} exact={true} />
            <Route path="/market-place" component={QmMarketPlace} exact={true} />
            <PrivateRoute token={token} path="/golf" component={Golf} exact={true} />
            <PrivateRoute token={token} path="/golf/:id" component={GolfDetails} />
            <PrivateRoute token={token} path="/marina" component={Marina} exact={true} />
            <PrivateRoute token={token} path="/aerodromo" component={Aerodromo} exact={true} />
            <PrivateRoute token={token} path="/centro-hipico" component={CentroHipico} exact={true} />
            <PrivateRoute token={token} path="/real-estate" component={RealEstate} exact={true} />
            <PrivateRoute token={token} path="/cascais-lifestyle" component={CascaisLifestyle} exact={true} />
            <PrivateRoute token={token} path="/perfil" component={Profile} exact={true} />
            <Route path="/quem-somos" component={QuemSomos} exact={true} />

            <Route path="/registo" component={Registo} exact={true} />
            <Route path="/login" component={Login} exact={true} />

            <Route path="/politica-privacidade" component={PoliticaPrivacidade} exact={true} />
            <Route path="/termos-e-condicoes" component={TermosCondicoes} exact={true} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </AuthContext.Provider>
  );
};

export default App;
