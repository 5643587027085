import React, { useContext } from "react";
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel } from "@ionic/react";
import { menuController } from "@ionic/core";

import colors from "../utils";
import { AuthContext } from "../context";

interface Props {
  user: any;
}

const Menu: React.FC<Props> = ({ user }) => {
  const authContext = useContext(AuthContext);
  const loginAndRegister = false;

  const closeMenu = () => {
    menuController.close();
  };

  const logout = () => {
    authContext.logout();
    authContext.token = false;
    authContext.user = false;
    menuController.close();
  };

  return (
    <IonMenu side="start" content-id="main-content">
      <IonHeader>
        <IonToolbar style={{ "--background": colors.darkGrey }}>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ "--background": colors.darkGrey }}>
        <IonList style={{ "--ion-background-color": colors.darkGrey }}>
          <IonItem routerLink="/" onClick={closeMenu}>
            <IonLabel>HOME</IonLabel>
          </IonItem>
          <IonItem routerLink="/qm-resorts" onClick={closeMenu}>
            <IonLabel>QM RESORTS</IonLabel>
          </IonItem>
          <IonItem routerLink="/qm-partners" onClick={closeMenu}>
            <IonLabel>QM PARTNERS</IonLabel>
          </IonItem>
          <IonItem routerLink="/qm-exclusive" onClick={closeMenu}>
            <IonLabel>QM EXCLUSIVE</IonLabel>
          </IonItem>
          <IonItem routerLink="/qm-communities" onClick={closeMenu}>
            <IonLabel>QM COMMUNITIES</IonLabel>
          </IonItem>
          <IonItem routerLink="/qm-family-office" onClick={closeMenu}>
            <IonLabel>QM FAMILY OFFICE</IonLabel>
          </IonItem>
          <IonItem routerLink="/market-place" onClick={closeMenu}>
            <IonLabel>QM MARKET PLACE</IonLabel>
          </IonItem>

          {false && (
            <IonItem routerLink="/golf" onClick={closeMenu}>
              <IonLabel>GOLF</IonLabel>
            </IonItem>
          )}

          {false && (
            <IonItem routerLink="/marina" onClick={closeMenu}>
              <IonLabel>MARINA</IonLabel>
            </IonItem>
          )}

          {false && (
            <IonItem routerLink="/aerodromo" onClick={closeMenu}>
              <IonLabel>AERÓDROMO</IonLabel>
            </IonItem>
          )}

          {false && (
            <IonItem routerLink="/centro-hipico" onClick={closeMenu}>
              <IonLabel>CENTRO HÍPICO</IonLabel>
            </IonItem>
          )}

          <IonItem routerLink="/real-estate" onClick={closeMenu}>
            <IonLabel>REAL ESTATE</IonLabel>
          </IonItem>
          <IonItem routerLink="/cascais-lifestyle" onClick={closeMenu}>
            <IonLabel>CASCAIS LIFESTYLE</IonLabel>
          </IonItem>
          <IonItem routerLink="/quem-somos" onClick={closeMenu}>
            <IonLabel>QUEM SOMOS</IonLabel>
          </IonItem>
          {loginAndRegister && !user && (
            <>
              <IonItem routerLink="/registo" onClick={closeMenu}>
                <IonLabel className="ion-text-uppercase">Registo</IonLabel>
              </IonItem>
              <IonItem routerLink="/login" onClick={closeMenu}>
                <IonLabel className="ion-text-uppercase">Login</IonLabel>
              </IonItem>
            </>
          )}
          {loginAndRegister && user && (
            <IonItem onClick={logout}>
              <IonLabel className="ion-text-uppercase">Logout</IonLabel>
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
