import React from "react";

interface Props {
  children: any;
}

const style = {
  marginBottom: "15px",
  fontSize: "10pt",
  lineHeight: "1.5",
};

const AppText: React.FC<Props> = ({ children }) => {
  return (
    <div className="app-text" style={style}>
      {children}
    </div>
  );
};

export default AppText;
