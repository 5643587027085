import React from "react";
import { IonImg } from "@ionic/react";
import AppSlider from "./AppSlider";
import AppText from "./AppText";
import AppSpacer from "./AppSpacer";

interface Props {
  blocks: any;
}

const WpBlocks: React.FC<Props> = ({ blocks }) => {
  blocks = blocks.filter((block: any) => block.className.indexOf("site-only") < 0);

  return (
    <>
      {blocks.map((block: any, index: any) => (
        <div key={index}>
          {block.blockName === "slider" && <AppSlider slider={block.slider} />}

          {block.blockName === "core/paragraph" && (
            <AppText>
              <div dangerouslySetInnerHTML={{ __html: block.text }}></div>
            </AppText>
          )}
          {block.blockName === "core/list" && (
            <AppText>
              <div dangerouslySetInnerHTML={{ __html: block.text }}></div>
            </AppText>
          )}
          {block.blockName === "core/buttons" && (
            <AppText>
              <div dangerouslySetInnerHTML={{ __html: block.text }}></div>
            </AppText>
          )}
          {block.blockName === "core/columns" && (
            <AppText>
              <div dangerouslySetInnerHTML={{ __html: block.text }}></div>
            </AppText>
          )}
          {block.blockName === "core/image" && block.image && (
            <div className="wp-core-image">
              <IonImg src={block.image} />
            </div>
          )}
          {block.blockName === "core/spacer" && <AppSpacer height={block.height} />}
        </div>
      ))}
    </>
  );
};

export default WpBlocks;
