import { IonBackButton, IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import Layout from "../components/Layout";
import ViewMode from "../components/ViewMode";

const title = "Golf";
const api = "https://qmlifestyle.com";

const Golf: React.FC = () => {
  const [page, setPage] = useState<any>(false);
  const [mode, setMode] = useState("grid");

  const loadPage = useCallback(() => {
    axios.get(`${api}/wp-json/qm/v1/golf`).then((response) => {
      setPage(response.data);
    });
  }, []);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  return (
    <Layout title={title}>
      {page && (
        <>
          <IonRow className="ion-justify-content-between">
            <IonCol>
              <IonBackButton text="Back" defaultHref="/" />
            </IonCol>
            <IonCol>
              <ViewMode mode={mode} setMode={setMode}></ViewMode>
            </IonCol>
          </IonRow>

          <IonGrid>
            <IonRow>
              {page.subpages.map((subpage: any) => (
                <IonCol size={mode === "grid" ? "6" : "12"} size-md="6" key={subpage.ID}>
                  <Link to={`/golf/${subpage.ID}`}>
                    <IonImg src={subpage.thumb} />
                  </Link>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </>
      )}
    </Layout>
  );
};

export default Golf;
