import React from "react";

interface Props {
  children: any;
}

const style = {
  margin: "30px 0 0 0",
  minHeight: "calc(100vh - 220px)",
};

const AppContent: React.FC<Props> = ({ children }) => {
  return <div style={style}>{children}</div>;
};

export default AppContent;
