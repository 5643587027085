import { IonButton, IonCheckbox, IonCol, IonDatetime, IonGrid, IonInput, IonItem, IonLabel, IonList, IonLoading, IonRow, IonSelect, IonSelectOption, useIonViewWillEnter } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "../utils/api";

import Layout from "../components/Layout";
import AppSpacer from "../components/AppSpacer";
import Error from "../components/Error";

const title = "Registo";

interface RegistrationFormData {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  birthDate: string;
  address: string;
  zipCode: string;
  city: string;
}

const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

const Registo: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [interesses, setInteresses] = useState([
    {
      label: "<strong>Motores</strong> (Carros Clássicos, Superdesportivos, Barcos e Yachts, Motos & Desportos Motorizados – F1, MotoGp, WRC)",
      val: "motores",
      isChecked: false,
    },
    {
      label: "<strong>Desportos e competições desportivas</strong> (Futebol, Ténis, NBA, Atletismo & Jogos Olímpicos, NFL, Golfe, Ciclismo, Rugby, Desportos Equestres, e mais)",
      val: "desporto",
      isChecked: false,
    },
    {
      label: "<strong>Espectáculos, Concertos e Festivais</strong> (Musica clássica, Musica Contemporânea, Festivais de Musica, Cinema, Arte e Moda, Teatro, Espectáculos Circenses, e mais)",
      val: "espectaculos",
      isChecked: false,
    },
    {
      label: "<strong>Vinhos, Gastronomia e Gourmet</strong>",
      val: "vinhos",
      isChecked: false,
    },
    {
      label: "<strong>Alta Joalharia, Relojoaria, Moda e Luxo</strong>",
      val: "joias",
      isChecked: false,
    },
    {
      label: "<strong>Arte</strong>",
      val: "arte",
      isChecked: false,
    },
    {
      label: "<strong>Viajar</strong> (Experiências Únicas, Villas de Férias, Alugueres de Yachts e Carros Superdesportivos, entre mais)",
      val: "viagens",
      isChecked: false,
    },
  ]);

  useIonViewWillEnter(() => {
    setFormSubmitted(false);
  });

  useEffect(() => {
    /*
    if (false) {
      const params = { shouldValidate: true };
      setValue("email", "test@email.com", params);
      setValue("firstName", "Carlos", params);
      setValue("lastName", "Santos", params);
      setValue("phone", "930000000", params);

      setValue("address", "Cascais", params);
      setValue("zipCode", "1234-123", params);
      setValue("city", "Cascais", params);
    }
    */
  });

  /**
   *
   * @param _fieldName
   */
  const showError: any = (_fieldName: string) => {
    return (
      _fieldName in errors && (
        <div
          style={{
            color: "red",
            marginBottom: 12,
            fontSize: "9pt",
          }}
        >
          {"Campo de preenchimento obrigatório"}
        </div>
      )
    );
  };

  /*
  let initialValues: RegistrationFormData = {
    email: "teste@teste.com",
    firstName: "Carlos",
    lastName: "Santos",
    phone: "",
    birthDate: "",
    address: "",
    zipCode: "",
    city: "",
  };
  */

  const { register, handleSubmit, errors, setValue, control } = useForm({
    //defaultValues: { ...initialValues },
    mode: "onChange",
  });

  const changeInteresses = (event: CustomEvent) => {
    const userInteresses = interesses.map((interesse) => {
      if (interesse.val === event.detail.value) {
        interesse.isChecked = event.detail.checked;
      }
      return interesse;
    });

    setInteresses(userInteresses);
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    setError("");

    const postData: any = {
      rest_route: "/simple-jwt-login/v1/users",
      email: data.email,
      password: "",
      first_name: data.firstName,
      last_name: data.lastName,
      telefone: data.phone,
      data_nascimento: data.birthDate,
      morada: data.address,
      codigo_postal: data.zipCode,
      localidade: data.city,
      country: data.country,
    };

    interesses.forEach((interesse) => {
      if (interesse.isChecked) {
        postData[interesse.val] = 1;
      } else {
        delete postData[interesse.val];
      }
    });

    const qs = Object.keys(postData)
      .map((key) => `${key}=${postData[key]}`)
      .join("&");

    axios
      .post(`/`, qs)
      .then((response) => {
        setLoading(false);
        setFormSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
        setError(error.response ? error.response.data.data.message : "Ocorreu um erro, tente de novo sff.");
        setLoading(false);
      });
  };

  return (
    <Layout title={title}>
      {formSubmitted && <div style={{ margin: 20, lineHeight: 1.4 }}>Registo efectuado com sucesso. Verifique o seu email para definir a senha de acesso.</div>}

      {!formSubmitted && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <IonLoading cssClass="frm-loading" isOpen={loading} message={"Aguarde..."} />
          {error && <Error>{error}</Error>}

          <IonList className="ion-padding">
            <IonItem>
              <IonLabel position="stacked">Email (obrigatório)</IonLabel>
              <IonInput type="email" name="email" ref={register({ required: true })}></IonInput>
              {showError("email")}
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Primeiro Nome (obrigatório)</IonLabel>
              <IonInput type="text" name="firstName" ref={register({ required: true })}></IonInput>
              {showError("firstName")}
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Último Nome (obrigatório)</IonLabel>
              <IonInput type="text" name="lastName" ref={register({ required: true })}></IonInput>
              {showError("lastName")}
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Telefone / Telemóvel (obrigatório)</IonLabel>
              <IonInput type="text" name="phone" ref={register({ required: true })}></IonInput>
              {showError("phone")}
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Data de Nascimento (obrigatório)</IonLabel>

              <Controller
                control={control}
                rules={{ required: true }}
                name="birthDate"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <IonDatetime displayFormat="DD/MM/YYYY" cancelText="Cancelar" doneText="Ok" placeholder="" onIonBlur={onBlur} onIonChange={onChange}></IonDatetime>
                )}
              />

              {false && (
                <>
                  <IonInput
                    type="text"
                    name="birthDate"
                    ref={register({ required: true })}
                    style={{
                      position: "absolute",
                      width: 1,
                      height: 1,
                    }}
                  ></IonInput>

                  <IonDatetime
                    displayFormat="DD/MM/YYYY"
                    cancelText="Cancelar"
                    doneText="Ok"
                    placeholder=""
                    onIonChange={(e: any) => {
                      setValue("birthDate", e.detail.value, { shouldValidate: true });
                    }}
                  ></IonDatetime>
                </>
              )}

              {showError("birthDate")}
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Morada (obrigatório)</IonLabel>
              <IonInput type="text" name="address" ref={register({ required: true })}></IonInput>
              {showError("address")}
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Código Postal (obrigatório)</IonLabel>
              <IonInput type="text" name="zipCode" ref={register({ required: true })}></IonInput>
              {showError("zipCode")}
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Localidade (obrigatório)</IonLabel>
              <IonInput type="text" name="city" ref={register({ required: true })}></IonInput>
              {showError("city")}
            </IonItem>

            <IonItem>
              <IonLabel>País</IonLabel>
              <IonSelect name="country" value={"Portugal"} placeholder="Seleccione" ref={register()} interfaceOptions={{ cssClass: "qm-select" }}>
                {countryList.map((country) => (
                  <IonSelectOption key={country} className="qm-option" value={country}>
                    {country}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonList>

          <div style={{ textAlign: "center", margin: "0 30px 10px 30px" }}>
            Lista de interesses QM Lifestyle <br />
            <span style={{ fontSize: 14 }}>(deve seleccionar pelo menos 3 opções)</span>
          </div>

          <IonList lines="none">
            {interesses.map(({ label, val, isChecked }) => (
              <IonItem key={val}>
                <IonLabel class="ion-text-wrap" style={{ fontSize: 12 }}>
                  <span dangerouslySetInnerHTML={{ __html: label }} />
                </IonLabel>
                <IonCheckbox slot="start" name={val} value={val} checked={isChecked} onIonChange={changeInteresses} />
              </IonItem>
            ))}
          </IonList>

          <IonGrid>
            <IonRow>
              <IonCol size="7">
                <IonButton type="submit" expand="block">
                  Registar
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton type="reset" expand="block" fill="outline">
                  Reset
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>

          <AppSpacer height={30} />
        </form>
      )}
    </Layout>
  );
};

export default Registo;
