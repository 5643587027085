import React from "react";
import { ReactComponent as WiFi } from "../assets/images/nowifi.svg";

const Offline: React.FC = () => {
  return (
    <div className="ion-text-center" style={{ marginTop: "50px" }}>
      <WiFi style={{ color: "#fff", maxWidth: "50px" }} />
      <h3>Whoops</h3>
      <p>Não foi detectada uma ligação à internet</p>
    </div>
  );
};

export default Offline;
