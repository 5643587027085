import React from "react";
import { IonIcon } from "@ionic/react";
import { logoFacebook, logoInstagram } from "ionicons/icons";

const logoSize = "30px";
const logoStyle = { width: logoSize, height: logoSize, margin: "20px 8px 20px 8px", color: "#fff" };

const AppSocial: React.FC = () => {
  return (
    <div className="social" style={{ display: "flex", justifyContent: "center" }}>
      <a href="https://www.facebook.com/QMLifestyle-115450836860323">
        <IonIcon icon={logoFacebook} style={logoStyle} />
      </a>
      <a href="https://www.instagram.com/qm_lifestyle__/">
        <IonIcon icon={logoInstagram} style={logoStyle} />
      </a>
    </div>
  );
};

export default AppSocial;
