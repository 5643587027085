import React, { useEffect, useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AuthContext } from "../context";

const PrivateRoute = ({ component, token, ...rest }: any) => {
  const location = useLocation();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (location.pathname !== "/login" && !token) {
      authContext.redirect = location.pathname;
      localStorage.setItem("redirect", location.pathname);
    }
  }, [location, token, authContext]);

  const routeComponent = (props: any) => (token ? React.createElement(component, props) : <Redirect to={{ pathname: "/login" }} />);
  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
