import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { IonHeader, IonTitle, IonToolbar, IonButtons, IonMenuButton, IonIcon, IonPopover, IonButton, IonList, IonItem, IonLabel } from "@ionic/react";
import { popoverController } from "@ionic/core";
import { personOutline } from "ionicons/icons";
import Logo from "../assets/images/qm-prata-trans-sm.png";

import { AuthContext } from "../context";

interface Props {
  title: string;
}

const AppHeader: React.FC<Props> = ({ title }) => {
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });

  const authContext = useContext(AuthContext);

  const logout = () => {
    popoverController.dismiss().then(() => {
      authContext.logout();
      authContext.token = false;
      authContext.user = false;
    });
  };

  const closeMenu = () => {
    setShowPopover({ showPopover: false, event: undefined });
  };

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton auto-hide="false"></IonMenuButton>
        </IonButtons>
        <IonTitle>
          <Link to="/">
            <img alt="QM Lifestyle" src={Logo} style={{ width: "auto", height: "48px", display: "block", margin: "0 auto" }} />
          </Link>
        </IonTitle>
        <IonButtons slot="end" style={{ width: "48px" }}>
          <IonButton
            onClick={(e: any) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e });
            }}
          >
            <IonIcon icon={personOutline} />
          </IonButton>

          <IonPopover cssClass="user-menu" event={popoverState.event} isOpen={popoverState.showPopover} onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
            <IonList>
              {authContext.user && (
                <>
                  <IonItem routerLink="/perfil" onClick={closeMenu}>
                    <IonLabel>A minha conta</IonLabel>
                  </IonItem>
                  <IonItem onClick={logout}>
                    <IonLabel>Logout</IonLabel>
                  </IonItem>
                </>
              )}

              {!authContext.user && (
                <>
                  <IonItem routerLink="/login" onClick={closeMenu}>
                    <IonLabel>Login</IonLabel>
                  </IonItem>
                  <IonItem routerLink="/registo" onClick={closeMenu}>
                    <IonLabel>Registo</IonLabel>
                  </IonItem>
                </>
              )}
            </IonList>
          </IonPopover>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default AppHeader;
