import { IonButton, IonInput, IonItem, IonLabel, IonList, IonLoading } from "@ionic/react";
import { Redirect } from "react-router-dom";
import React, { useState, useContext } from "react";
import axios from "../utils/api";

import { AuthContext } from "../context";
import { useInput } from "../hooks/useInput";
import Layout from "../components/Layout";
import AppSpacer from "../components/AppSpacer";
import Error from "../components/Error";

const title = "Área Reservada";

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  //const [token, setToken] = useState(false);
  const [error, setError] = useState("");

  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");

  const authContext = useContext(AuthContext);

  /*
  useEffect(() => {
    console.log("useEffect");
  }, []);
  */

  const getJwtToken = async () => {
    try {
      const data = `rest_route=/simple-jwt-login/v1/auth&email=${email}&password=${password}`;
      const response = await axios.post(`/`, data);
      if (response.data.success === true) {
        return response.data.data.jwt;
      }
    } catch (error) {
      setError(error.response ? error.response.data.data.message : "Ocorreu um erro, tente de novo sff.");
    }

    return false;
  };

  const getUser = async (token: string) => {
    try {
      const response = await axios.get(`/?rest_route=/simple-jwt-login/v1/auth/validate&JWT=${token}`);
      if (response.data.success === true) {
        console.log(response.data);
        return response.data.data.user;
      }
    } catch (error) {
      return false;
    }
  };

  const handleLogin = (evt: any) => {
    evt.preventDefault();

    setLoading(true);
    setError("");
    getJwtToken().then((token) => {
      if (!token) {
        setLoading(false);
        return false;
      }
      getUser(token).then((user) => {
        setLoading(false);
        //setToken(token);
        authContext.login(token, user);
        authContext.token = token;
        authContext.user = user;
      });
    });
  };

  const handleLogout = (evt: any) => {
    evt.preventDefault();
    //setToken(false);
    authContext.logout();
    authContext.token = false;
    authContext.user = false;
  };

  return (
    <Layout title={title}>
      {!authContext.token && (
        <form onSubmit={handleLogin} noValidate>
          <IonLoading cssClass="frm-loading" isOpen={loading} message={"Aguarde..."} />
          {error && <Error>{error}</Error>}

          <IonList className="ion-padding">
            <IonItem>
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput type="email" {...bindEmail}></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput type="password" {...bindPassword}></IonInput>
            </IonItem>
          </IonList>
          <IonButton type="submit" expand="block" style={{ margin: "0 10px" }}>
            Login
          </IonButton>

          <AppSpacer height={30} />
        </form>
      )}

      {authContext.token && (
        <>
          <Redirect to={localStorage.getItem("redirect") || "/"} />

          <form onSubmit={handleLogout}>
            <IonButton type="submit" expand="block" style={{ margin: "0 10px" }}>
              Logout
            </IonButton>
          </form>
        </>
      )}
    </Layout>
  );
};

export default Login;
