import React from "react";
import { IonSegment, IonSegmentButton, IonIcon } from "@ionic/react";
import { gridOutline, listOutline } from "ionicons/icons";

interface Props {
  mode: string;
  setMode: any;
}

const ViewMode: React.FC<Props> = ({ mode, setMode }) => {
  return (
    <div style={{ margin: "0 10px 10px 10px", display: "flex", justifyContent: "flex-end" }}>
      <IonSegment
        style={{ maxWidth: "140px" }}
        color="tertiary"
        mode="ios"
        value={mode}
        onIonChange={(e) => {
          setMode(e.detail.value || "tes");
        }}
      >
        <IonSegmentButton value="list">
          <IonIcon size="small" icon={listOutline} />
        </IonSegmentButton>
        <IonSegmentButton value="grid">
          <IonIcon size="small" icon={gridOutline} />
        </IonSegmentButton>
      </IonSegment>
    </div>
  );
};

export default ViewMode;
