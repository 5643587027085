import { IonBackButton, IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import Layout from "../components/Layout";
import ViewMode from "../components/ViewMode";

const title = "QM Exclusive";
const api = "https://qmlifestyle.com";

const QmExclusive: React.FC = () => {
  const [pages, setPages] = useState<any>(false);
  const [mode, setMode] = useState("grid");

  const loadPages = useCallback(() => {
    axios.get(`${api}/wp-json/qm/v1/qm-exclusive`).then((response) => {
      setPages(response.data);
    });
  }, []);

  useEffect(() => {
    loadPages();
  }, [loadPages]);

  return (
    <Layout title={title}>
      {pages && (
        <>
          <IonRow className="ion-justify-content-between">
            <IonCol>
              <IonBackButton text="Back" defaultHref="/" />
            </IonCol>
            <IonCol>
              <ViewMode mode={mode} setMode={setMode}></ViewMode>
            </IonCol>
          </IonRow>

          <IonGrid>
            <IonRow>
              {pages.map((page: any) => (
                <IonCol size={mode === "grid" ? "6" : "12"} size-md="6" key={page.ID}>
                  <Link to={`/exclusive/${page.ID}`}>
                    <IonImg src={page.thumb} />
                  </Link>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </>
      )}
    </Layout>
  );
};

export default QmExclusive;
