import { IonBackButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import Layout from "../components/Layout";
import WpBlocks from "../components/WpBlocks";

const api = "https://qmlifestyle.com";

const QmMarketPlace: React.FC = () => {
  const [page, setPage] = useState<any>(false);
  const [title, setTitle] = useState<any>("");

  const loadPage = useCallback(() => {
    axios.get(`${api}/wp-json/wp/v2/pages/1200`).then((response) => {
      setPage(response.data);
      setTitle(response.data.title.rendered);
    });
  }, []);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  console.log(page.blocks);

  return (
    <Layout title={title}>
      <div className="ion-margin-bottom">
        <IonBackButton text="Back" defaultHref="/" />
      </div>

      {page && (
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <WpBlocks blocks={page.blocks} />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </Layout>
  );
};

export default QmMarketPlace;
