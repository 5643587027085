import React from "react";

interface Props {
  children: string;
}

const AppTitle: React.FC<Props> = ({ children }) => {
  return (
    <h2 className="page-title ion-text-center ion-text-uppercase">
      <div dangerouslySetInnerHTML={{ __html: children }}></div>
    </h2>
  );
};

export default AppTitle;
