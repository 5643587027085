/**
 * https://rangle.io/blog/simplifying-controlled-inputs-with-hooks/
 *
 * value={email} onIonChange={(e) => setEmail(e.detail.value!)}
 */
import { useState } from "react";

export const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onIonChange: (event) => {
        setValue(event.detail.value);
      },
    },
  };
};
