import { IonCol, IonGrid, IonImg, IonRouterLink, IonRow } from "@ionic/react";
import React, { useEffect, useState, useCallback } from "react";
import { Redirect, useParams } from "react-router-dom";
import axios from "axios";

import Layout from "../components/Layout";

interface ParamTypes {
  id: string | undefined;
}

const api = "https://qmlifestyle.com";

const QmPartnersCat: React.FC = () => {
  const { id } = useParams<ParamTypes>();

  const [title, setTitle] = useState<any>("");
  const [partners, setPartners] = useState<any>(false);
  const [redirctTo, setRedirctTo] = useState<any>(false);

  const loadPartners = useCallback(() => {
    axios.get(`${api}/wp-json/wp/v2/partnerscat/${id}`).then((response) => {
      setTitle(response.data.name);
    });

    axios.get(`${api}/wp-json/wp/v2/partner?partnerscat=${id}&_embed`).then((response) => {
      setPartners(response.data);
    });
  }, [id]);

  useEffect(() => {
    if (id && +id === 29) {
      setRedirctTo("/partner/790");
    } else {
      loadPartners();
    }
  }, [loadPartners, id]);

  if (redirctTo) {
    return <Redirect to={{ pathname: redirctTo }} />;
  }

  return (
    <Layout title={title} back={true}>
      {partners && (
        <IonGrid>
          <IonRow>
            {partners.map((partner: any) => (
              <IonCol size="12" size-md="6" key={partner.id}>
                <IonRouterLink href={`/partner/${partner.id}`}>{partner.thumb && <IonImg src={partner.thumb} />}</IonRouterLink>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      )}
    </Layout>
  );
};

export default QmPartnersCat;
