import React from "react";

interface Props {
  height: number | null;
}

const AppSpacer: React.FC<Props> = ({ height }) => {
  if (!height) {
    return <div></div>;
  }

  return <div className="app-text" style={{ height: height + "px" }}></div>;
};

export default AppSpacer;
