/*
if (error.response) {
  setError("offline"); // client received an error response (5xx, 4xx)
} else if (error.request) {
  setError("offline"); // client never received a response, or request never left
} else {
  setError("offline"); // anything else
}
*/

export const QmApiError = (error: any) => {
  return "offline";
};

export const QmApi = (name: string, id?: number) => {
  const base = "https://qmlifestyle.com";

  if (name === "home") {
    return `${base}/wp-json/wp/v2/pages/86`;
  }

  if (name === "resorts") {
    return `${base}/wp-json/wp/v2/resort?per_page=20&orderby=menu_order&order=asc&_embed`;
  }

  if (name === "partners") {
    return "";
  }

  if (name === "exclusive") {
    return "";
  }

  if (name === "communities") {
    return "";
  }

  if (name === "market-place") {
    return "";
  }

  if (name === "golf") {
    return "";
  }

  if (name === "marina") {
    return "";
  }

  if (name === "aerodromo") {
    return "";
  }

  if (name === "centro-hipico") {
    return "";
  }

  if (name === "real-estate") {
    return "";
  }

  if (name === "quem-somos") {
    return "";
  }

  if (name === "registo") {
    return "";
  }

  return "";
};

const colors = {
  darkGrey: "#171717",
};

export default colors;
