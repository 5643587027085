import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Layout from "../components/Layout";
import WpBlocks from "../components/WpBlocks";

interface ParamTypes {
  id: string | undefined;
}

const api = "https://qmlifestyle.com";

const QmResortDetails: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const [resort, setResort] = useState<any>(false);
  const [title, setTitle] = useState<any>("");

  const fields = ["website", "contacto", "reserva", "marcacoes", "facebook", "instagram"];

  const loadResort = useCallback(() => {
    axios.get(`${api}/wp-json/wp/v2/resort/${id}`).then((response) => {
      setTitle(response.data.title.rendered);
      setResort(response.data);
    });
  }, [id]);

  useEffect(() => {
    loadResort();
  }, [loadResort]);

  return (
    <Layout title={title} back={true}>
      {resort && (
        <IonGrid>
          <IonRow>
            <IonCol>
              <WpBlocks blocks={resort.blocks} />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}

      {resort && (
        <div className="partners-meta">
          {fields
            .filter((field) => resort[field])
            .map((field: string, index: number) => (
              <a key={index} href={resort[field]}>
                {field}
              </a>
            ))}
        </div>
      )}
    </Layout>
  );
};

export default QmResortDetails;
