import { IonBackButton, IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import Layout from "../components/Layout";
import ViewMode from "../components/ViewMode";

const title = "PARCEIROS RECOMENDADOS";
const api = "https://qmlifestyle.com";

const QmPartners: React.FC = () => {
  const [partnersCats, setPartnersCats] = useState<any>(false);
  const [mode, setMode] = useState("grid");

  const loadPartnersCats = useCallback(() => {
    axios.get(`${api}/wp-json/qm/v1/qm-partner`).then((response) => {
      setPartnersCats(response.data);
    });
  }, []);

  useEffect(() => {
    loadPartnersCats();
  }, [loadPartnersCats]);

  return (
    <Layout title={title}>
      {partnersCats && (
        <>
          <IonRow className="ion-justify-content-between">
            <IonCol>
              <IonBackButton text="Back" defaultHref="/" />
            </IonCol>
            <IonCol>
              <ViewMode mode={mode} setMode={setMode}></ViewMode>
            </IonCol>
          </IonRow>

          <IonGrid>
            <IonRow>
              {partnersCats.map((cat: any) => (
                <IonCol size={mode === "grid" ? "6" : "12"} size-md="6" key={cat.term_id}>
                  <Link to={`/partners/${cat.term_id}`}>
                    <IonImg src={cat.thumb[0]} />
                  </Link>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </>
      )}
    </Layout>
  );
};

export default QmPartners;
