import { IonBackButton, IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { QmApi, QmApiError } from "../utils";
import Layout from "../components/Layout";
import ViewMode from "../components/ViewMode";

const title = "QM Resorts";

const QmResorts: React.FC = () => {
  const [resorts, setResorts] = useState<any>(false);
  const [mode, setMode] = useState("grid");
  const [error, setError] = useState<any>("");

  const loadResorts = useCallback(() => {
    axios
      .get(QmApi("resorts"))
      .then((response) => {
        setResorts(response.data);
      })
      .catch((error) => {
        setError(QmApiError(error));
      });
  }, []);

  useEffect(() => {
    loadResorts();
  }, [loadResorts]);

  return (
    <Layout title={title} offline={error === "offline"}>
      {resorts && (
        <>
          <IonRow className="ion-justify-content-between">
            <IonCol>
              <IonBackButton text="Back" defaultHref="/" />
            </IonCol>
            <IonCol>
              <ViewMode mode={mode} setMode={setMode}></ViewMode>
            </IonCol>
          </IonRow>

          <IonGrid>
            <IonRow>
              {resorts.map((resort: any) => (
                <IonCol size={mode === "grid" ? "6" : "12"} size-md="6" key={resort.id}>
                  <Link to={`/resort/${resort.id}`}>
                    <IonImg src={resort["_embedded"]["wp:featuredmedia"][0].source_url} />
                  </Link>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </>
      )}
    </Layout>
  );
};

export default QmResorts;
