import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Layout from "../components/Layout";
import WpBlocks from "../components/WpBlocks";

interface ParamTypes {
  id: string | undefined;
}

let title = "Parceiros Recomendados";
const api = "https://qmlifestyle.com";

const QmPartner: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const [partner, setPartner] = useState<any>(false);

  const fields = ["website", "contacto", "reserva", "marcacoes", "facebook", "instagram"];

  const loadPartner = useCallback(() => {
    axios.get(`${api}/wp-json/wp/v2/partner/${id}`).then((response) => {
      setPartner(response.data);
    });
  }, [id]);

  useEffect(() => {
    loadPartner();
  }, [loadPartner]);

  if (partner) {
    title = partner.title.rendered;
  }

  return (
    <Layout title={title} back={true}>
      {partner && (
        <IonGrid>
          <IonRow>
            <IonCol>
              <WpBlocks blocks={partner.blocks} />
            </IonCol>
          </IonRow>
        </IonGrid>
      )}

      {partner && (
        <div className="partners-meta">
          {fields
            .filter((field) => partner[field])
            .map((field: string, index: number) => (
              <a key={index} href={partner[field]}>
                {field}
              </a>
            ))}
        </div>
      )}
    </Layout>
  );
};

export default QmPartner;
